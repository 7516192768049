<template>
  <div class="page__not--found">
    <div class="page-inner">
      <img src="@/assets/images/not-found.svg" alt="Not Found" />
      <div class="nf-heading">
        {{ $t("error.page_not_found.title") }}
      </div>
      <div class="nf-content">
        {{ description }}
      </div>
      <div class="nf__btn--container">
        <LxpButton
          class="d-inline-flex"
          @click="$router.push({ path: '/' })"
          :variant="exploreButton ? 'link' : 'primary'"
        >
          {{ $t("error.page_not_found.button") }}
        </LxpButton>
        <LxpButton
          class="d-inline-flex"
          @click="
            $router.push({ path: `/self-paced/${$route.params.id}/forums` })
          "
          v-if="exploreButton"
        >
          {{ $t("error.post_not_found.button") }}
        </LxpButton>
      </div>
    </div>
  </div>
</template>
<script>
import { LxpButton } from "didactica";
export default {
  props: {
    description: {
      type: String,
      required: true
    },
    exploreButton: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LxpButton
  }
};
</script>
<style lang="scss">
.page__not--found {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 517px;
  margin: 32px auto 0px;
  .nf-heading {
    @include h3;
    color: $brand-public-primary;
    margin-top: 20px;
  }
  .nf-content {
    @include subtitle-regular;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 32px;
  }
  .nf__btn--container {
    display: flex;
    align-items: center;
    justify-content: center;
    > *:not(:first-child) {
      margin-left: 32px;
    }
  }
}
</style>
