<template>
  <div class="co-modals">
    <b-modal
      :id="DISCUSSION_FORUM_MODAL_IDS.DELETE_POST_MODAL"
      centered
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <template #modal-header>
        <div class="lxp-modal__header">
          <div
            class="close-icon-styles"
            @click="closeModal(DISCUSSION_FORUM_MODAL_IDS.DELETE_POST_MODAL)"
          >
            <img src="@/assets/images/close.svg" alt="close" />
          </div>
        </div>
      </template>
      <div class="lxp-modal__body">
        <h3 class="lxp-modal__title ">
          {{ $t("discussions.modal.delete.title") }}
        </h3>
        <p class="lxp-modal__subtitle">
          {{ $t("discussions.modal.delete.content") }}
        </p>
      </div>
      <template #modal-footer>
        <div class="lxp-modal__footer post__modal--footer">
          <LxpButton
            variant="outline-primary"
            @click="closeModal(DISCUSSION_FORUM_MODAL_IDS.DELETE_POST_MODAL)"
            class="dismiss-button"
          >
            {{ $t("course.unenroll.buttons.dismiss") }}
          </LxpButton>
          <LxpButton
            @click="$emit('delete-post')"
            variant="outline-danger"
            class="delete-button"
          >
            {{ $t("general.delete") }}
          </LxpButton>
        </div>
      </template>
    </b-modal>
    <b-modal
      :id="DISCUSSION_FORUM_MODAL_IDS.REPORT_POST_MODAL"
      centered
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      modal-class="report__post--modal"
    >
      <template #modal-header>
        <div class="lxp-modal__header">
          <div
            class="close-icon-styles"
            @click="closeModal(DISCUSSION_FORUM_MODAL_IDS.REPORT_POST_MODAL)"
          >
            <img src="@/assets/images/close.svg" alt="close" />
          </div>
        </div>
      </template>
      <div class="lxp-modal__body">
        <h3 class="lxp-modal__title ">
          {{ $t("discussions.modal.report.title") }}
        </h3>
        <p class="lxp-modal__subtitle">
          {{ $t("discussions.modal.report.content") }}
        </p>
      </div>
      <div class="report-options">
        <label
          class="report-label"
          v-for="(option, index) in reportOptions"
          :key="index"
        >
          <input
            type="radio"
            :value="option"
            class="report-radio"
            @click="handleClick(option)"
            v-model="selectedOption"
          />
          <span class="report-text">{{ option }}</span>
          <input
            type="text"
            :maxlength="200"
            :placeholder="otherPlaceholder"
            class="other-details"
            :class="{ 'show-input': option === 'Other' }"
            :disabled="selectedOption !== 'Other'"
            v-model="otherDetails"
          />
        </label>
      </div>
      <template #modal-footer>
        <div class="lxp-modal__footer post__modal--footer">
          <LxpButton
            variant="outline-primary"
            @click="closeModal(DISCUSSION_FORUM_MODAL_IDS.REPORT_POST_MODAL)"
            class="dismiss-button"
          >
            {{ $t("general.cancel") }}
          </LxpButton>
          <LxpButton
            :disabled="
              !selectedOption || (selectedOption === 'Other' && !otherDetails)
            "
            @click="submitReport"
            variant="outline-danger"
            class="delete-button"
          >
            {{ $t("general.report") }}
          </LxpButton>
        </div>
        <div class="report-note">
          {{ $t("discussions.modal.report.options.note") }}
        </div>
      </template>
    </b-modal>
    <b-modal
      :id="DISCUSSION_FORUM_MODAL_IDS.SAVE_DRAFT_MODAL"
      centered
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      modal-class="draft__post--modal"
    >
      <template #modal-header>
        <div class="lxp-modal__header">
          <div
            class="close-icon-styles"
            @click="closeModal(DISCUSSION_FORUM_MODAL_IDS.SAVE_DRAFT_MODAL)"
          >
            <img src="@/assets/images/close.svg" alt="close" />
          </div>
        </div>
      </template>
      <div class="lxp-modal__body">
        <h3 class="lxp-modal__title ">
          {{ $t("discussions.modal.draft.title") }}
        </h3>
        <p class="lxp-modal__subtitle">
          {{ $t("discussions.modal.draft.content") }}
        </p>
      </div>
      <template #modal-footer>
        <div class="lxp-modal__footer post__modal--footer">
          <LxpButton
            variant="outline-primary"
            @click="closeModal(DISCUSSION_FORUM_MODAL_IDS.SAVE_DRAFT_MODAL)"
            class="dismiss-button"
          >
            {{ $t("discussions.modal.draft.discard") }}
          </LxpButton>
          <LxpButton
            @click="$emit('draft-post')"
            variant="primary"
            class="delete-button"
          >
            {{ $t("general.save") }}
          </LxpButton>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { LxpButton } from "didactica";
import DISCUSSION_FORUM_MODAL_IDS from "./constants.js";
export default {
  components: { LxpButton },
  props: {
    reportType: String,
    reportId: String
  },
  data() {
    return {
      DISCUSSION_FORUM_MODAL_IDS: {},
      reportOptions: [
        this.$t("discussions.modal.report.options.hate_speech"),
        this.$t("discussions.modal.report.options.harrassment_bullying"),
        this.$t("discussions.modal.report.options.violence"),
        this.$t("discussions.modal.report.options.false_information"),
        this.$t("discussions.modal.report.options.other")
      ],
      otherPlaceholder: this.$t(
        "discussions.modal.report.options.other_placeholder"
      ),
      selectedOption: "",
      otherDetails: ""
    };
  },
  created() {
    this.DISCUSSION_FORUM_MODAL_IDS = DISCUSSION_FORUM_MODAL_IDS;
  },
  mounted() {
    this.$parent.$on("report-post-success", () => {
      this.selectedOption = "";
      this.otherDetails = "";
    });
  },
  methods: {
    closeModal(id) {
      this.$bvModal.hide(id);
      this.selectedOption = "";
      this.otherDetails = "";
    },
    handleClick(option) {
      if (option !== "Other") {
        this.otherDetails = "";
      }
    },
    submitReport() {
      const reportData =
        this.selectedOption === "Other"
          ? this.otherDetails
          : this.selectedOption;
      this.$emit("report-post", {
        reportData,
        reportType: this.reportType,
        reportId: this.reportId
      });
    }
  }
};
</script>
