var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add__response--wrapper"},[_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('form',{staticClass:"response-form",on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('div',{class:[
          'add__response--inner',
          { 'height-increased': _vm.isHeightIncreased }
        ]},[_c('div',{staticClass:"response__input--wrapper"},[_c('div',{staticClass:"post-user"},[_c('div',{staticClass:"user-image",class:{
                'user-bg': _vm.hasProfileImage
              }},[_c('img',{attrs:{"src":_vm.userImageUrl,"alt":"avatar"}})])]),_c('quill-editor',{ref:"myQuillEditor",class:[
              'add__response--input',
              {
                'toxic-input': _vm.isResponseToxic,
                'input-focused': _vm.isEditorFocused || _vm.responseBody !== ''
              }
            ],attrs:{"options":_vm.editorOption},on:{"blur":function($event){return _vm.onEditorBlur($event)},"focus":function($event){return _vm.onEditorFocus($event)},"ready":function($event){return _vm.onEditorReady($event)}},model:{value:(_vm.responseBody),callback:function ($$v) {_vm.responseBody=$$v},expression:"responseBody"}}),_c('div',{staticClass:"add-emoji"},[_c('ImageButton',{attrs:{"defaultIcon":_vm.addEmojiIcon,"hoveredIcon":_vm.addEmojiHoverIcon,"focusedIcon":_vm.addEmojiHoverIcon,"pressedIcon":_vm.addEmojiPressedIcon,"altText":"Emoji"}})],1)],1),(_vm.isResponseLoading)?_c('LxpLoader',{staticClass:"response-spinner"}):(_vm.getSelectedDiscussionPost?.is_facilitator)?_c('div',{ref:"dropdownContainer",class:[
            'submit-response',
            {
              'disable-response': invalid || _vm.disableResponseBtn,
              hovered: _vm.isAddResponseIconHovered || _vm.isResponseDropdownOpen
            }
          ]},[_c('img',{attrs:{"src":_vm.submitResponseIcon,"alt":"Submit"},on:{"mouseover":function($event){_vm.isAddResponseIconHovered = true},"mouseout":function($event){_vm.isAddResponseIconHovered = false},"click":_vm.responseDropdown}}),(_vm.isResponseDropdownOpen)?_c('div',{staticClass:"share__response--list"},[_c('Button',{staticClass:"share__list--item",attrs:{"type":"submit"},on:{"click":function($event){return _vm.setSubmitType('publicly')}}},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("discussions.new_response.button.publicly.title"))+" ")]),_c('span',{staticClass:"subtext"},[_vm._v(" "+_vm._s(_vm.$t("discussions.new_response.button.publicly.content"))+" ")])]),_c('Button',{staticClass:"share__list--item",attrs:{"type":"submit"},on:{"click":function($event){return _vm.setSubmitType('privately')}}},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("discussions.new_response.button.privately.title"))+" ")]),_c('span',{staticClass:"subtext"},[_vm._v(" "+_vm._s(_vm.$t("discussions.new_response.button.privately.content"))+" ")])])],1):_vm._e()]):_c('button',{class:[
            'submit-response',
            {
              hovered: _vm.isAddResponseIconHovered
            }
          ],attrs:{"disabled":invalid || _vm.disableResponseBtn,"type":"submit"}},[_c('img',{attrs:{"src":_vm.submitResponseIcon,"alt":"Submit"},on:{"mouseover":function($event){_vm.isAddResponseIconHovered = true},"mouseout":function($event){_vm.isAddResponseIconHovered = false}}})])],1),(_vm.isResponseToxic)?_c('div',{staticClass:"error-text toxic-error"},[_vm._v(" "+_vm._s(_vm.$t("discussions.new_post.toast.toxic.response.note"))+" ")]):_vm._e(),(_vm.showError && _vm.responseBody !== '')?_c('div',{staticClass:"error-text"},[_vm._v(" "+_vm._s(_vm.$t("discussions.new_response.error.title"))+" ")]):_vm._e(),(_vm.responseBodyLength >= _vm.textLimit && _vm.responseBody !== '')?_c('div',{staticClass:"counter-error"},[_vm._v(" "+_vm._s(_vm.responseBodyLength)+" / "+_vm._s(_vm.textLimit)+" ")]):_vm._e()])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }