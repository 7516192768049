import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getSelectedCourse"]),
    youtubeThumbnail() {
      let intro_url = this.getSelectedCourse.intro_video_url;
      let thumnailUrl = "";
      if (this.getSelectedCourse && this.getSelectedCourse.intro_video_url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var value = intro_url.match(regExp);
        if (value && value[7].length === 11) {
          thumnailUrl = "//img.youtube.com/vi/" + value[7] + "/0.jpg";
        }
      }
      return thumnailUrl;
    }
  },
  methods: {
    copyUrl() {
      let cDetail = `https://${window.location.host}` + this.$route.fullPath;
      var input = document.createElement("input");
      input.setAttribute("value", cDetail);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 3000);
    }
  }
};
